import { useEffect, useRef, useCallback, useState } from 'react';

const LOAD_COOLDOWN = 1000;
const LOAD_THRESHOLD_PERCENT = 40;

const useScrollAndLoad = (
  messages,
  isLoadingOlderMessages,
  loadMoreMessages,
  isOpen
) => {
  const messagesListRef = useRef(null);
  const lastLoadTime = useRef(0);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);

  const previousScrollHeight = useRef(0);
  const previousMessagesLength = useRef(messages.length);

  const scrollToBottom = useCallback((smooth = true) => {
    if (messagesListRef.current) {
      messagesListRef.current.scrollTo({
        top: messagesListRef.current.scrollHeight,
        behavior: smooth ? 'smooth' : 'auto',
      });
      setIsScrolledToBottom(true);
    }
  }, []);

  const handleLoadMore = useCallback(() => {
    const now = Date.now();
    if (now - lastLoadTime.current > LOAD_COOLDOWN && !isLoadingOlderMessages) {
      if (messagesListRef.current) {
        previousScrollHeight.current = messagesListRef.current.scrollHeight;
      }
      loadMoreMessages();
      lastLoadTime.current = now;
    }
  }, [loadMoreMessages, isLoadingOlderMessages]);

  const handleScroll = useCallback(() => {
    const list = messagesListRef.current;
    if (!list) return;

    const { scrollTop, scrollHeight, clientHeight } = list;
    const scrollPosition = scrollTop;
    const totalScrollableHeight = scrollHeight - clientHeight;
    const scrollPercentage = (scrollPosition / totalScrollableHeight) * 100;

    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 1;
    setIsScrolledToBottom(isAtBottom);

    if (scrollPercentage <= LOAD_THRESHOLD_PERCENT) {
      handleLoadMore();
    }
  }, [handleLoadMore]);

  useEffect(() => {
    const list = messagesListRef.current;
    if (list) {
      list.addEventListener('scroll', handleScroll);
      return () => list.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (isOpen && messages.length > 0) {
      scrollToBottom(false);
    }
  }, [isOpen, scrollToBottom]);

  useEffect(() => {
    const list = messagesListRef.current;
    if (!list) return;

    if (isLoadingOlderMessages) {
      return;
    }

    const newMessagesLength = messages.length;

    if (previousMessagesLength.current < newMessagesLength) {
      if (previousScrollHeight.current) {
        const newScrollHeight = list.scrollHeight;
        const scrollDifference = newScrollHeight - previousScrollHeight.current;
        list.scrollTop += scrollDifference;
        previousScrollHeight.current = 0;
      } else if (isScrolledToBottom) {
        scrollToBottom();
      }
    }

    previousMessagesLength.current = newMessagesLength;
  }, [messages, isLoadingOlderMessages, isScrolledToBottom, scrollToBottom]);

  return { messagesListRef, isScrolledToBottom, scrollToBottom };
};

export default useScrollAndLoad;
