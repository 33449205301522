import { useEffect } from 'react';

const useWidgetState = (isOpen, initialLoading, setInitialLoading, messageListHeight, messagesListRef) => {
  useEffect(() => {
    if (messagesListRef.current) {
      const messageListArea = messagesListRef.current;
      if (messageListArea) {
        messageListArea.style.paddingBottom = `calc(var(--sab, 0px) + ${messageListHeight}px)`;
      }
    }
  }, [messageListHeight, messagesListRef]);

  useEffect(() => {
    if (isOpen) {
      if (initialLoading) {
        setInitialLoading(false);
      }
    }
    window.parent.postMessage({ type: 'WIDGET_STATE_CHANGE', isOpen }, '*');
  }, [isOpen, initialLoading, setInitialLoading]);
};

export default useWidgetState;