import React from 'react';
import LoadingSkeleton from '../LoadingSkeleton/LoadingSkeleton';
import Message from '../Message/Message';
import './MessageList.css';

const MessageList = ({
  messagesListRef,
  isLoadingOlderMessages,
  isLoading,
  messages,
  retrySendMessage,
  handleStreamingComplete,
  isScrolledToBottom,
}) => {
  return (
    <ul className="messages-list" ref={messagesListRef}>
      <div className="messages-list-content">
        {isLoadingOlderMessages && (
          <div className="spinner-messages">
            <LoadingSkeleton />
          </div>
        )}
        {!isLoading && messages.length > 0 ? (
          messages.map((msg, index) => (
            <Message
              key={msg.id || index}
              {...msg}
              index={index}
              retrySendMessage={retrySendMessage}
              prevTimestamp={
                index > 0 ? messages[index - 1].timestamp : undefined
              }
              onStreamingComplete={() => handleStreamingComplete(index)}
              isScrolledToBottom={isScrolledToBottom}
            />
          ))
        ) : (
          !isLoadingOlderMessages && <LoadingSkeleton />
        )}
      </div>
    </ul>
  );
};

export default MessageList;
