import React, { createContext, useState, useEffect, useContext } from 'react';
import { AuthContext } from './AuthContext';
import { getWidgetConfiguration } from '../api/apiService';

export const defaultTheme = {
  primaryColorLight: "#17DAFD",
  secondaryColorLight: "#6590D5",
  backgroundColorLight: "#FFFFFF59",
  textColorLight: "#000000",
  errorColorLight: "#68dbf2",
  errorBgLight: "#ffe5e5",
  primaryColorDark: "#00A3D9",
  secondaryColorDark: "#3A6BC5",
  backgroundColorDark: "#FFFFFF59",
  textColorDark: "#000000",
  errorColorDark: "#FF8080",
  errorBgDark: "#ffe5e5",
  blurEffectBackgroundColor: "#a0a0a015"
};

export const WidgetThemeContext = createContext({
  theme: defaultTheme,
  loading: true,
});

export const WidgetThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(defaultTheme);
  const [loading, setLoading] = useState(true);
  const { user, organizationId, agentId } = useContext(AuthContext);

  useEffect(() => {
    const fetchTheme = async () => {
      if (organizationId && agentId && user) {
        try {
          const response = await getWidgetConfiguration(user.accessToken, organizationId, agentId, user.id);
          const fetchedTheme = response?.data || {};
          setTheme({ ...defaultTheme, ...fetchedTheme }); // Merge with defaultTheme
        } catch (error) {
          console.error('Failed to fetch widget theme:', error);
          setTheme(defaultTheme); // Fallback to default theme if fetch fails
        } finally {
          setLoading(false); // Loading is complete regardless of success or failure
        }
      } else {
        setLoading(false); // If no user or IDs, consider loading complete
      }
    };

    fetchTheme();
  }, [organizationId, agentId, user]);

  return (
    <WidgetThemeContext.Provider value={{ theme, loading }}>
      {children}
    </WidgetThemeContext.Provider>
  );
};

export const useWidgetTheme = () => useContext(WidgetThemeContext);
