import { useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ConversationContext } from '../contexts/ConversationContext';

const useMessageHandling = (setIsOpen) => {
  const { setUser, refreshAuth } = useContext(AuthContext);
  const { setOrganizationId, setAgentId } = useContext(ConversationContext);

  useEffect(() => {
    const handleMessage = (event) => {
      switch (event.data.type) {
        case 'INITIALIZE':
          setOrganizationId(event.data.organizationId);
          setAgentId(event.data.agentId);
          setUser(event.data.userData);
          break;
        case 'SET_USER_DATA':
          setUser(event.data.userData);
          break;
        case 'CLEAR_USER_DATA':
          setUser(null);
          break;
        case 'OPEN_WIDGET':
          setIsOpen(true);
          break;
        case 'CLOSE_WIDGET':
          setIsOpen(false);
          break;
        case 'TOGGLE_WIDGET':
          setIsOpen(prev => !prev);
          break;
        case 'REFRESH_AUTH':
          refreshAuth();
          break;
        default:
          break;
      }
    };

    window.addEventListener('message', handleMessage);
    window.parent.postMessage({ type: 'WIDGET_READY' }, '*');

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [setIsOpen, setUser, refreshAuth, setOrganizationId, setAgentId]);
};

export default useMessageHandling;