import React, { useState } from 'react';
import { AuthProvider } from './contexts/AuthContext';
import { ConversationProvider } from './contexts/ConversationContext';
import { WidgetThemeProvider } from './contexts/WidgetThemeContext';
import ChatbotWidget from './ChatbotWidget';
import useMessageHandling from './hooks/useMessageHandling';

const AppContent = () => {
  const [isOpen, setIsOpen] = useState(false);
  useMessageHandling(setIsOpen);

  return <ChatbotWidget isOpen={isOpen} />;
};

const App = () => {
  return (
    <AuthProvider>
      <WidgetThemeProvider>
        <ConversationProvider>
          <AppContent />
        </ConversationProvider>
      </WidgetThemeProvider>
    </AuthProvider>
  );
};

export default App;