import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [agentId, setAgentId] = useState(null);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'INITIALIZE') {
        setOrganizationId(event.data.organizationId);
        setAgentId(event.data.agentId);
        setUser(event.data.userData);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const refreshAuth = () => {
    window.parent.postMessage({ type: 'REFRESH_AUTH' }, '*');
  };

  return (
    <AuthContext.Provider value={{ user, setUser, organizationId, agentId, refreshAuth }}>
      {children}
    </AuthContext.Provider>
  );
};